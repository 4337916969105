import Link from "next/link";

import { useDispatch } from "react-redux";
import { walletModalShow, connectWalletShow} from "../../redux/counterSlice";
import { useMetaMask } from "metamask-react";

const Partners = () => {


  const dispath = useDispatch();
  const { status, connect, account, chainId, ethereum } = useMetaMask();

  const connectWallet = () => {
    if (status === "notConnected") {
      dispath(connectWalletShow());
    }
  };
  const partnerGroup = [
    { id: 1, link: "https://www.nft-dao.org/", logo: "partner_logo_1" },
    { id: 2, link: "https://www.nft-dao.org/", logo: "partner_logo_2" },
    { id: 3, link: "https://www.nft-dao.org/", logo: "partner_logo_3" },
    { id: 4, link: "https://www.nft-dao.org/", logo: "partner_logo_4" },
    { id: 5, link: "https://www.nft-dao.org/", logo: "partner_logo_5" },
  ];
  return (
    <>
      <div className="bg-white dark:bg-jacarta-900 ">
        <div className="container">
          <div className="grid grid-cols-2 py-8 sm:grid-cols-5">
            {partnerGroup.map((item) => (
              <a
                onClick={connectWallet}
                rel="noopener noreferrer"
                key={item.id}
              >
                <img
                  src={`/images/partners/${item.logo}.png`}
                  width="150"
                  alt="partner 1"
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;
