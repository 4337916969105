import { display } from "@mui/system";
import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cartModalHide } from "../../redux/counterSlice";
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CachedIcon from '@mui/icons-material/Cached';

import Link from 'next/link';

//Formik
import { useFormik } from 'formik';

//Axios
import axios from "axios";
import config from "../../utils/config.js";

//Local Storage Session 
import { ReactSession } from 'react-client-session';


const Cart_modal = ({i, price, service}) => {
  const cartModal = useSelector((state) => state.counter.cartModal);
  const dispatch = useDispatch();
  
  //  AXIOS REQUEST                            
  const client = axios.create({ baseURL: `${config.API_URL}`});
  const auth = { headers: { Authorization: `Bearer ${config.API_KEY}`}};


  const [ redirect, setRedirect ] = useState(null);
  const [orders, setOrders] = useState();
  const [id, setId] = useState();
  const [code, setCode] = useState();
  const [date, setDate] = useState();
  const [user, setUser] = useState();
  const [deliveryDate, setDeliveryDate] = useState();
  const [subTotal, setSubTotal] = useState();
  const [discount, setDiscount] = useState();
  const [serviceFee, setServiceFee] = useState();
  const [amount, setAmount] = useState();
  const [status, setStatus] = useState();

  ReactSession.setStoreType("localStorage");

  let formik = {};




  useEffect(() => {


  
    //GET orders
    client.get(`/order`, auth)
    .then((response) => { 

      setOrders(response.data); 

        const i = parseInt(response.data.count) + 1;

        formik.values.code = "OJ2300"+i;
        formik.values.id = i.toString();
    
    })
    .catch(error => { console.error(error) });


   //alert (JSON.stringify(service));
    const a = parseFloat(price[i].price*(config.SERVICE_CHARGE/100));
    const b = parseFloat(price[i].price);
    const c = a + b;

    const d = new Date();
    const e = new Date();
    const f = parseInt(price[i].duration);
    e.setDate(e.getDate() + f);


    const activity_id = 1  ;
    const activity = {[activity_id] : {
        id : activity_id,
        user: ReactSession.get("_id"),
        image: 'images/icons/file.svg',
        message: "placed this order",
        type: "notice", // notice, message
        section: "activity", // activity, requirements, delivery, resolution
        updated: new Date()
    }};

    
    formik.values.date = d;
    formik.values.user = ReactSession.get("_id");   
    formik.values.service = service?._id;
    formik.values.discount = 0;
    formik.values.details = price[i];
    formik.values.deliveryDate = e;
    formik.values.subTotal = b;
    formik.values.serviceFee = a;
    formik.values.amount = c;
    formik.values.status = "started";
    formik.values.activity = JSON.stringify(activity);
  
 

  }, []);

 

  formik = useFormik({
    initialValues: {
      id:id,
      code: code,
      date: date,
      user: user,
      service: service,
      date:date,
      discount:discount,
      deliveryDate:deliveryDate,
      subTotal:subTotal,
      serviceFee:serviceFee,
      amount:amount,
      status:status

    },
    onSubmit: (values) => {
      

        //alert(JSON.stringify(values, null, 2));
        client.post("/order", values , auth)
        .then((response) => {
          //alert("200: OK - CHECK DB");
          //setOrder(response.data); 

          window.location.href = `/checkout/${response.data._id}`;
    
        })
        .catch(error => { console.error(error) });


    },
  });


  //alert(i+""+JSON.stringify(price));
  return (
     
      <div className={cartModal ? "block modal fade show" : "modal fade hidden"}>
        <div className="modal-dialog max-w-lg right">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="cartModalLabel">
                Order Options
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(cartModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <form onSubmit={formik?.handleSubmit}>
            <div className="modal-body p-4">
                <div className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-2 md:p-4">
                   
                      
                        {/* <!-- Details --> */}
                         <div className="dark:bg-jacarta-700 rounded-t-2lg rounded-b-2lg rounded-tl-none bg-white p-2 ">
                                <div className="w-full flex justify-between">
                                <h1 className="font-display text-jacarta-700 mb-6 text-2xl font-bold dark:text-white">
                                    {price[i].package}
                                </h1>
                                <h1 className="font-display text-jacarta-700 mb-6 text-2xl font-bold dark:text-white">
                                    $ {price[i].price}
                                </h1>
                                </div>
                               
                                <p>{price[i].description}</p>
                                <br/>

                                <div className="flex space-x-4 mt-2 mb-6">
                                    <div className="w-1/2 flex space-x-4"> 
                                    <AccessTimeIcon/>
                                    <div>
                                        <span className="mb-3 block font-display text-base font-semibold text-jacarta-700 dark:text-white">
                                            {price[i].duration} Days Delivery
                                        </span>
                                    </div>
                                    </div>
                                    <div className="w-1/2 flex space-x-4"> 
                                    <CachedIcon/>
                                    <div>
                                        <span className="mb-3 block font-display text-base font-semibold text-jacarta-700 dark:text-white">
                                            {price[i].revisions} &nbsp;Revisions
                                        </span>
                                    </div>
                                    </div>
                                </div>

                        
                                {price[i].features?.map((item) => {
                                    return (
                                            <>
                                            <div className="flex space-x-4">

                                            <CheckIcon className="font-display"/>
                                            <div>
                                                <span className="mb-3 block text-base text-jacarta-400 dark:text-white">
                                                    {item}
                                                </span>
                                            </div>
                                            </div>
                                            </>
                                            );
                                    })}
                                        

                                  
                                {/*<pre >{JSON.stringify(formik.values, null, 2)}</pre>*/}
                                 
                            
                            </div>
                      

                </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer text-center">
                <button 
                  type="submit" className="bg-jacarta-700 w-full  hover:bg-accent-dark rounded-full py-3 px-8 font-bold text-center text-white transition-all"
                >
                  Continue to Checkout
                </button>

               
              <span className="mt-4 text-sm text-jacarta-400">You won't be charged yet</span>
            </div>
            </form>
          </div>
        </div>
      </div>
  );
};

export default Cart_modal;
