import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'tippy.js/dist/tippy.css';
import Browse_category_data from '../../data/Browse_category_data';
import Link from 'next/link';
import Image from "next/image";
const Browse_category_carousel = ({categories}) => {
	return (
		<div className="overflow-hidden">
			<Swiper
				slidesPerView="auto"
				spaceBetween={10}
				loop={true}
				breakpoints={{
					// when window width is >= 640px
					100: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					// when window width is >= 768px
					700: {
						slidesPerView: 4,
						spaceBetween: 20,
					},
					900: {
						slidesPerView: 5,
						spaceBetween: 20,
					},
					1200: {
						slidesPerView: 7,
						spaceBetween: 30,
					},
				}}
				className=" card-slider-4-columns !py-2"
				style={{ transform: 'scaleX(1.2)' }}
			>
				{categories?.data.map((item) => {
					const { _id, id, image, name, featured } = item;
					return (
						<>
						{featured &&
						<>
						<SwiperSlide key={id}>
							<article>
								<Link href={`/category/${_id}`} >
									<a className=" bg-white dark:bg-jacarta-700 rounded-xl block  p-0 transition-shadow hover:shadow-lg">
										<figure className={` rounded-t-[0.625rem] w-full rounded-[0.625rem`}>
											<img src={image} alt={name} width="300" className="card_bg w-full rounded-xl" />
										</figure>
										<div className="-mt-20 px-4 pb-6  text-left card_overlay">
											<span className="font-display max-w-[200px] text-jacarta-700 text-white text-lg font-display dark:text-white">
												{name}
											</span>
										</div>
									</a>
								</Link>
							</article>
						</SwiperSlide>
						</>
						}
						</>
					);
				})}
			</Swiper>
		</div>
	);
};

export default Browse_category_carousel;
