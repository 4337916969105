const slider_1_data = [
	{
		id: '0',
		image: '/images/slider/slider_1.png',
		title: 'Start your own business today',
		name: 'Hire assistance to register and incorporate your company ',
		titleLink: '#',
		userLink: '#',
	},
	{
		id: '1',
		image: '/images/slider/slider_2.png',
		title: 'Grow your online presence now',
		name: 'Create, launch and grow your website with the help of qualified professionals',
		titleLink: '#',
		userLink: '#',
	},
	{
		id: '2',
		image: '/images/slider/slider_3.png',
		title: 'Start a new brand',
		name: 'Get a new logo and brand concept from high rated creatives',
		titleLink: '#',
		userLink: '#',
	},
	{
		id: '3',
		image: '/images/slider/slider_4.png',
		title: 'Start selling online with your E-Commerce Business',
		name: 'Grow your business by expanding online with the help of experienced web developers',
		titleLink: '#',
		userLink: '#',
	},
	{
		id: '4',
		image: '/images/slider/slider_5.png',
		title: 'Create and publish your content ideas',
		name: 'Publish your book, presentation or video of your content',
		titleLink: '#',
		userLink: '#',
	},
];



export { slider_1_data };
