import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import OfferTab from "./OfferTab";
import Properties from "./Properties";
import Activity_tab from "./Activity_tab";
import Price_history from "./Price_history";

import Reviews from "../reviews/user_reviews";

import Activity_item from "../../pages/order/activity";

import Profile_Service_Items from "../profile/list/profile_services_items";

const ItemsTabs = ({loading, services , reviews}) => {
  const [tabsActive, setTabsActive] = useState(1);
  const tabsHeadText = [
    {
      id: 1,
      text: "Services",
      icon: "Services",
    },
    {
      id: 2,
      text: "Orders",
      icon: "Orders",
    },
    {
      id: 3,
      text: "Earnings",
      icon: "Earnings",
    },
    {
      id: 4,
      text: "Reviews",
      icon: "Reviews",
    },
    {
      id: 5,
      text: "Transactions",
      icon: "Transactions",
    },
  ];
  return (
    <>
      <div className="scrollbar-custom  overflow-x-auto rounded-lg">
        {/* <!-- Tabs Nav --> */}
        <Tabs className="min-w-fit tabs">
          <TabList className="nav nav-tabs flex items-center ">
            {/* <!-- Offers --> */}
            {tabsHeadText.map(({ id, text, icon }) => {
              return (
                <Tab className="nav-item bg-transparent mr-12" key={id}>
                  <button
                    className={
                      tabsActive === id
                        ? "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white active"
                        : "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
                    }
                    onClick={() => setTabsActive(id)}
                  >
                    <span className="text-lg font-display">
                      {text}
                    </span>
                  </button>
                </Tab>
              );
            })}
          </TabList>

          <TabPanel>
            <Profile_Service_Items loading={loading} services={services}/>
           
          </TabPanel>

          <TabPanel>
            <Reviews  reviews={reviews}/>
           
          </TabPanel>
          <TabPanel>
              <OfferTab />
          </TabPanel>
          <TabPanel>
              <Price_history classes="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6" />
          </TabPanel>
          <TabPanel>
              <OfferTab />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default ItemsTabs;
