import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { review_data } from '../../data/review_data';
import Link from 'next/link';
import Image from 'next/image';
import Rating from '@mui/material/Rating';

import StarIcon from '@mui/icons-material/Star';


import LinearProgress, {linearProgressClasses} from "@mui/material/LinearProgress";

import { styled } from "@mui/material/styles";
import config from "../../utils/config.js";

const Reviews = ({reviews}) => {


	const [filterVal, setFilterVal] = useState(null);
	function onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}

	const [data, setData] = useState(review_data);
	const [filterData, setfilterData] = useState(
		review_data.map((item) => {
			const { category } = item;
			return category;
		})
	);

	const [inputText, setInputText] = useState('');

	const handleFilter = (category) => {
		setData(review_data.filter((item) => item.category === category));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const newArray = review_data.filter((item) => {
			return item.username.toLowerCase().includes(inputText);
		});
		setData(newArray);
		setInputText('');
	};

	useEffect(() => {
		setfilterData(filterData.filter(onlyUnique));
	}, []);

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 10,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
		  backgroundColor:
			theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
		},
		[`& .${linearProgressClasses.bar}`]: {
		  borderRadius: 5,
		  backgroundColor: theme.palette.mode === "light" ? "#FAAF00" : "#308fe8"
		}
	  }));

	const review_count = reviews?.data.length; // Number pf reviews

	//Calculate the Earners Reputation
	function getReputation(){
		let earner_reputation = null;
		let sum = 0;

		let count1 = 0,  count2 = 0, count3 = 0, count4 = 0, count5 =  0;
		let per1 = 0, per2 = 0, per3 = 0, per4 = 0, per5 =  0;

		let comm = 0, qual = 0, rec= 0;
		let communication = 0, quality = 0, recommend= 0;
		
		
		reviews?.data?.map((item) => {
			const { rating } = item;
			const review_rating =  parseFloat((parseInt(rating.communication) + parseInt(rating.quality) + parseInt(rating.recommend)) / 3).toFixed(1);
			sum = parseFloat(parseFloat(sum) + parseFloat(review_rating));

			comm = parseFloat(parseFloat(comm) + parseInt(rating.communication));
			qual = parseFloat(parseFloat(qual) + parseInt(rating.quality));
			rec = parseFloat(parseFloat(rec) + parseInt(rating.recommend));

			if(review_rating==5){ count5 = count5 + 1; }
			else if (review_rating>=4 && review_rating<5 ){ count4 = count4 + 1;}
			else if (review_rating>=3 && review_rating<4 ){ count3 = count3 + 1;}
			else if (review_rating>=2 && review_rating<3 ){ count2 = count2 + 1;}
			else if (review_rating>=0 && review_rating<2 ){ count1 = count1 + 1;}


			console.log("REVIEW RATING::: "+review_rating+" SUM::: "+sum);
		});

		/*console.log("EARNER REPUTATION::: "+earner_reputation);*/
		per1 = parseFloat(count1/review_count)*100;
		per2 = parseFloat(count2/review_count)*100;
		per3 = parseFloat(count3/review_count)*100;
		per4 = parseFloat(count4/review_count)*100;
		per5 = parseFloat(count5/review_count)*100;

		communication = parseFloat(comm/review_count).toFixed(1);
		quality = parseFloat(qual/review_count).toFixed(1);
		recommend = parseFloat(rec/review_count).toFixed(1);



		earner_reputation = parseFloat(parseFloat(sum)/ parseInt(review_count)).toFixed(1);
		return { earner_reputation, count1, count2, count3, count4, count5, per1, per2, per3, per4, per5, communication, quality, recommend}
		
	}
	
	const rep = getReputation();
	if (review_count>0){
	return (
		<>
			
		
		</>
	)}
};

export default Reviews;
