import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/image";
import "tippy.js/dist/tippy.css";
import { items_data } from "../../data/items_data";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { bidsModalShow } from "../../redux/counterSlice";
import { useDispatch } from "react-redux";
import Likes from "../likes";

const BidsCarousel = () => {
  const dispatch = useDispatch();
  const handleclick = () => {
    console.log("clicked on ");
  };
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}
        spaceBetween={20}
        slidesPerView="auto"
        loop={false}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          565: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
          1100: {
            slidesPerView: 4,
          },
          1340: {
            slidesPerView: 4,
          },
        }}
        navigation={{
          nextEl: ".bids-swiper-button-next",
          prevEl: ".bids-swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {items_data.map((item) => {
          const { id, image, title, price, likes, ownerName, owner } =
            item;
          const itemLink = image
            .split("/")
            .slice(-1)
            .toString()
            .replace(".jpg", "");
          return (
            <SwiperSlide className="text-white" key={id}>
              <article>
                <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white transition-shadow hover:shadow-lg text-jacarta-500">
                  <figure>
                    {/* {`item/${itemLink}`} */}
                    <Link href={"/item/" + id}>
                      <a>
                        <div className="w-full">
                          <Image
                            src={image}
                            alt={title}
                            height={250}
                            width={400}
                            objectFit="cover"
                            className="rounded-[0.625rem] w-full"
                            loading="lazy"
                          />
                        </div>
                      </a>
                    </Link>
                  </figure>
                  <div className="p-[1.1875rem]">
                      <div className="mt-2 text-sm flex align-center">
                        <span className="dark:text-jacarta-300 text-jacarta-500">
                        <Link href={`/item/${itemLink}`}>
                          <a>
                            <Tippy content={<span>creator: {owner.name}</span>}>
                              <img
                                src={owner.image}
                                alt="owner"
                                className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
                              />
                            </Tippy>
                          </a>
                        </Link>
                        </span>
                        <span className="dark:text-jacarta-100 text-jacarta-700">
                          {owner.name}
                        </span>
                      </div>
                      <div className="mt-4 flex items-center justify-between">
                        <Link href={"/item/" + itemLink}>
                          <a>
                            <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                              {title}
                            </span>
                          </a>
                        </Link>
                      </div>
                      

                      <div className="mt-3 flex items-center justify-between">
                        <Likes
                          like={likes}
                          classes="flex items-center space-x-1"
                        />
                          <span className="text-green text-md font-medium tracking-tight">
                            <span className="text-xs text-jacarta-400  text-default">FROM  </span>${price} 
                          </span>
                      </div>
                  </div>
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <!-- Slider Navigation --> */}
      <div className="group bids-swiper-button-prev swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group bids-swiper-button-next swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default BidsCarousel;
