import React, { useEffect, useState } from "react";
import { tranding_category_filter } from "../../../data/categories_data";
import ServiceItem from "./profile_service_item";
import { items_data } from "../../../data/items_data";
import Tippy from "@tippyjs/react";
import Recently_added_dropdown from "../../dropdown/recently_added_dropdown";
import { useSelector, useDispatch } from "react-redux";
import { updateTrendingCategoryItemData } from "../../../redux/counterSlice";

const Profile_Services_Items = ({loading, services}) => {
  const [itemdata, setItemdata] = useState(items_data);
  const dispatch = useDispatch();
  const { trendingCategorySorText } = useSelector((state) => state.counter);
  const [filterVal, setFilterVal] = useState(0);

  const handleFilter = (category) => {
    if (category !== "all") {
      setItemdata(
        items_data.filter((item) => item.category === category)
      );
    } else {
      setItemdata(items_data);
    }
  };

  const sortText = [
    {
      id: 1,
      text: "Recently Added",
    },
    {
      id: 2,
      text: "Price: Low to High",
    },
    {
      id: 3,
      text: "Price: high to low",
    },
    {
      id: 4,
      text: "Auction Ending Soon",
    },
  ];

  useEffect(() => {
    dispatch(updateTrendingCategoryItemData(itemdata.slice(0, 6)));
  }, [itemdata, dispatch]);

  return (
    <>
     

      {/* <!-- Grid --> */}
      <ServiceItem  loading={loading} services={services} />
    </>
  );
};

export default Profile_Services_Items;
