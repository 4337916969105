const review_data = [
	{
		id: '0',
		image: '/images/avatars/avatar_2.jpg',
		username: 'Lazyone Panda',
        country: 'France',
		price: 'sold for 1.515 ETH',
		time: '30 minutes 45 seconds ago',
		category: 'purchases',
        comment: 'Honestly the best! Would definitely recommend',
        reply: "Great pleasure working with you! Thank you for choosing me :)",
	},
	{
		id: '1',
		image: '/images/avatars/avatar_1.jpg',
		username: 'NFT Funny Cat',
        country: 'United States',
		price: 'listed by 051_Hart .08095 ETH',
		time: '40 minutes 36 seconds ago',
		category: 'listing',
        comment: 'Wow, incredible work! Got every detail exactly right on the first delivery, including little details like hover state gradients. The site renders perfectly on different screen sizes, and everything is set up in Webflow in a way that will be easy for us to update. Very impressed with how fast and thorough this was. Thank you! ',
        reply: "Great pleasure working with you! Thank you for choosing me :)",
	},
	{
		id: '2',
		image: '/images/avatars/avatar_3.jpg',
		username: 'Prince Ape Planet',
        country: 'UK',
		price: 'tranferred from 027ab52',
		time: '1 hour 15 minutes ago',
		category: 'transfer',
        comment: 'Wow! I am absolutely blown away with the work that he did. It looks exactly how our figma file looked and we are so happy with the results! I can\'t say enough good things!',
        reply: "Great pleasure working with you! Thank you for choosing me :)",
	},
	{
		id: '3',
		image: '/images/avatars/avatar_4.jpg',
		username: 'Origin Morish',
        country: 'Nigeria',
		price: 'bid cancelled by 0397fd',
		time: '1 hour 55 minutes ago',
		category: 'bids',
        comment: "He's very communicative, works on time, did animations on my website extra. He's a great sense of design - he had a figma file of desktop website, but did mobile version on his own. Professional and communicative, great freelancer. I'm very happy with taking him to my project",
        reply: "Great pleasure working with you! Thank you for choosing me :)",
	},
	{
		id: '4',
		image: '/images/avatars/avatar_5.jpg',
		username: 'Portrait Gallery#029',
        country: 'United States',
		price: 'liked by Trina_more',
		time: '2 hours 24 minutes ago',
		category: 'likes',
        comment: "Amazing experience again. Have worked together 2 times now and I will never work with anyone else for webflow. Attention to detail and amazingly fast turnaround.",
        reply: "Great pleasure working with you! Thank you for choosing me :)",
	},
	{
		id: '5',
		image: '/images/avatars/avatar_6.jpg',
		username: 'Portrait Gallery#029',
        country: 'United States',
		price: 'liked by Trina_more',
		time: '2 hours 24 minutes ago',
		category: 'likes',
        comment: "Alex, was very communicative and transparent. He addressed any concerns I had in a timely manner. I was impressed by the design of the website and look forward to building a long term business relationship with him. I highly recommend his services.",
        reply: "Great pleasure working with you! Thank you for choosing me :)",
	},
	{
		id: '6',
		image: '/images/avatars/avatar_3.jpg',
		username: 'Lazyone Panda',
        country: 'Italy',
		price: 'sold for 1.515 ETH',
		time: '30 minutes 45 seconds ago',
		category: 'purchases',
        comment: "I plan on using Alexander for any future webdesign projects. Very good person to work with.",
        reply: "Great pleasure working with you! Thank you for choosing me :)",
	},
	{
		id: '7',
		image: '/images/avatars/avatar_2.jpg',
		username: 'NFT Funny Cat',
        country: 'United States',
		price: 'listed by 051_Hart .08095 ETH',
		time: '40 minutes 36 seconds ago',
		category: 'listing',
        comment: "Alexander has been excellent to work with from start to finish. He has fulfilled the project on time and met expectations, while communicating adequately throughout. Looking forward to working with him again.",
        reply: "Great pleasure working with you! Thank you for choosing me :)",
	},
	{
		id: '8',
		image: '/images/avatars/avatar_5.jpg',
		username: 'Prince Ape Planet',
        country: 'Belgium',
		price: 'tranferred from 027ab52',
		time: '1 hour 15 minutes ago',
		category: 'transfer',
        comment: 'Honestly the best! Would definitely recommend',
        reply: "Great pleasure working with you! Thank you for choosing me :)",
	},
	{
		id: '9',
		image: '/images/avatars/avatar_6.jpg',
		username: 'Origin Morish',
        country: 'Germany',
		price: 'bid cancelled by 0397fd',
		time: '1 hour 55 minutes ago',
		category: 'bids',
        comment: 'Honestly the best! Would definitely recommend',
        reply: "Great pleasure working with you! Thank you for choosing me :)",
	},
	{
		id: '10',
		image: '/images/avatars/avatar_3.jpg',
		username: 'Portrait Gallery#029',
        country: 'United States',
		price: 'liked by Trina_more',
		time: '2 hours 24 minutes ago',
		category: 'likes',
        comment: 'Honestly the best! Would definitely recommend',
        reply: "Great pleasure working with you! Thank you for choosing me :)",
	},
	{
		id: '11',
		image: '/images/avatars/avatar_1.jpg',
		username: 'Portrait Gallery#029',
        country: 'United States',
		price: 'liked by Trina_more',
		time: '2 hours 24 minutes ago',
		category: 'bids',
        comment: 'Honestly the best! Would definitely recommend',
        reply: "Great pleasure working with you! Thank you for choosing me :)",
	},
];

export {review_data};
