import React, { useEffect, useState } from 'react';
import Image from "next/image";
import Link from "next/link";
import Typer from './Typer'
import Chip from '@mui/material/Chip';


const Hero = () => {

  
  const [search, setSearch] = useState('');
  
  const [searchText, setSearchText] = useState('Search');

  const changeSearch = (val) => {
    //console.log(content);
    setSearch(val.target.value);

  };
  const searchSubmit = (e) => {
    e.preventDefault();
    if(search){
      window.location.href = `/search/`+search;
    }else{
      setSearchText("What are you looking for?");
    }
    
  }

  return (
    <>
      {/* <!-- Hero --> */}
      <section className="relative pb-0 pt-10 lg:pt-32">
          <picture className="pointer-events-none absolute inset-0 top-0 -z-10 block dark:hidden">
            <img
              src="/images/hero/hero_bg.png"
              alt=""
              className="h-full w-full object-cover"
            />
          </picture>
          <picture className="pointer-events-none absolute inset-0 top-0 -z-10 hidden dark:block">
            <img
              src="/images/hero/hero_bg_dark.png"
              alt=""
              className="h-full w-full  object-cover"
            />
          </picture>
        
        {/* hero img 
                <img
          src="/images/patterns/pattern_donut.png"
          alt="pattern donut"
          className="absolute right-0 top-0 -z-10"
        />
        */}


        <div className="ml-auto mr-auto h-full max-w-[91rem] px-4">
          <div className="grid h-full items-center gap-4 lg:grid-cols-12">
            <div className="col-span-12 flex h-full flex-col items-center justify-center py-10 lg:items-start lg:py-20">
              <p className="mb-4 text-sm font-bold uppercase  dark:text-jacarta-200">
                Hire and Earn On the Blockchain
              </p>
              <h1 className="mb-6  text-center font-display text-5xl text-jacarta-700 dark:text-white lg:text-left lg:text-6xl">
                Hire the best <Typer heading="" dataText={['logo & branding', 'web design', 'social media', 'graphic design', 'e commerce', 'video production', 'mobile apps', 'writing & editing', 'translation']} /> 
                <br/>services for your 
                <br/>organization
              </h1>
              <p className="mb-8 max-w-lg text-center text-lg dark:text-jacarta-200 lg:text-left ">
                Find the most qualified professionals using ratings stored on the blockchain. Make and receive payments using crypto.
              </p>
                <div className="w-full mb-10">
                  <div className="max-w-lg text-left">
                    <form className="relative" onSubmit={searchSubmit}>
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={changeSearch}
                        value={search}
                        className="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-3 px-4 dark:text-white dark:placeholder-white"
                      />
                      <button className="hover:bg-accent-dark font-display bg-accent absolute top-2 right-2 rounded-full px-6 py-2 text-sm text-white">
                        Search
                      </button>
                    </form>
                    <div className="flex w-full mt-4">
                        <a href="/category/64b217d839a34c8762b66968"><Chip className="border-jacarta-900 dark:text-white" size="sm" label="Logo Design" variant="soft" /></a>
                        <a href="/category/64b217d839a34c8762b66969"><Chip className="border-jacarta-900 dark:text-white  ml-4" size="sm" label="Website Design" variant="soft" /></a>
                        <a href="/category/64b217d839a34c8762b66976"><Chip className="border-jacarta-900 dark:text-white ml-4" size="sm" label="Social Media" variant="soft" /></a>
                    </div>
                  </div>
              </div>
            </div>

        
          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero;


