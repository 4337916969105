import "swiper/css";
import "swiper/css/navigation";
import { HeadLine } from "../component";
import "tippy.js/dist/tippy.css";
import BidsCarousel from "../carousel/bidsCarousel";

const Lists = () => {
  return (
    <section className="pt-16 pb-10 bg-lightgray">
      {/* <!-- Hot Bids --> */}

        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full"
          />
        </picture>
    
      <div className="container max-w-[91rem] px-4">
        <div className="flex justify-between mb-4">
          <div>
              <h2 className="font-display text-2xl text-jacarta-700 dark:text-white">Continue Browsing </h2>
              <span className="detail">Recent services you viewed.</span>
          </div>
          <a className="hover:text-accent">See all</a>
        </div>

        <div className="relative">
          {/* <!-- Slider --> */}
          <BidsCarousel />
        </div>
      </div>
      {/* <!-- end hot bids --> */}
    </section>
  );
};

export default Lists;
