import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Likes from "../../likes";
import Auctions_dropdown from "../../dropdown/Auctions_dropdown";
import { useDispatch, useSelector } from "react-redux";
import { buyModalShow } from "../../../redux/counterSlice";

import config from "../../../utils/config.js";



const ServiceItem = ({loading, services}) => {
  const { sortedtrendingCategoryItemData } = useSelector(
    (state) => state.counter
  );
  const dispatch = useDispatch();
  const skeleton = Array(4).fill("")

  return (
    <div className="list pt-8">
      
      <div className="mb-10 shrink-0 basis-8/12 space-y-5 lg:mb-0 lg:pr-10">
                            
                            {loading&&
                                <>
                                <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
                                {skeleton.map((count) => (
                                <div key={count} className="c-item rounded-2xl">
                                    <div className="c-item__top">
                                    <div className="c-skeleton-square" />
                                    </div>
                                    <div className="c-item__center">
                                    <h3>
                                        <div className="c-skeleton-line" />
                                        <div className="c-skeleton-line" />
                                    </h3>
                                    </div>
                                    <div className="c-item__bottom">
                                    <div className="c-skeleton-line" />
                                    <div className="c-skeleton-line" />
                                    </div>
                                    <div className="c-item__add">
                                    <div className="c-skeleton-square" />
                                    </div>
                                    </div>
                                ))}
                                </div>
                                
                            </>
                            }

                            
                            {services?.data.length==0&&
                                
                            <div className="w-full text-center py-28"> 
                                <h2 className="text-2xl font-display dark:text-white">No services yet.</h2>
                                <p className="text-md text-jacarta-300 dark:text-white">Check back later for services coming soon.</p>
                            </div>
                            
                            }
                            <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">


                            {services?.data.length>0&&
                                services?.data.sort((a, b) => b.id - a.id).map((item) => {
                                const {
                                _id,
                                id,
                                image,
                                user,
                                title,
                                price,
                                } = item;
                                const itemLink = image
                                .split("/")
                                .slice(-1)
                                .toString()
                                .replace(".jpg", "")
                                .replace(".gif", "");
                                return (
                                <article key={id}>
                                    <Link href={"/service/" + _id}>
                                    <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white transition-shadow hover:shadow-lg text-jacarta-500">
                                        <figure>
                                            {/* {`item/${itemLink}`} */}
                                            
                                            <a>
                                                <div className="w-full">
                                                <img
                                                    src={config.IMAGE_URL+"/"+image}
                                                    height={150}
                                                    width={290}
                                                    className="rounded-[0.625rem] w-full"
                                                    loading="lazy"
                                                />
                                                </div>
                                            </a>
                                            
                                        </figure>
                                        <div className="p-[1.1875rem]">

                                                {[user]?.map((item) => {
                                                    const {
                                                        _id,
                                                        id,
                                                        image,
                                                        username,
                                                        firstName,
                                                        lastName,
                                                    
                                                    } = user;
                                                    return (
                                                            <>  
                                                                <div className="mt-2 text-sm flex align-center">
                                                                    <span className="dark:text-jacarta-300 text-jacarta-500">
                                                                    <Link href={`/profile/${_id}`}>
                                                                    <a>
                                                                        <Tippy content={<span>creator: {username}</span>}>
                                                                        <img src={image ? config.IMAGE_URL+"/"+image : config.IMAGE_URL+"/"+config.DEFAULT_USER_IMAGE}
                                                                            className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
                                                                        />
                                                                        </Tippy>
                                                                    </a>
                                                                    </Link>
                                                                    </span>
                                                                    <span className="dark:text-jacarta-100 text-jacarta-700 ml-1 mt-1">
                                                                    {firstName ? firstName+" "+lastName : username}
                                                                    </span>
                                                                </div>
                                                        </>
                                                    );
                                                })}
                                            
                                            <div className="mt-4 flex items-center justify-between">
                                                    <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                                                    {title}
                                                    </span>
                                            </div>
                                            

                                            <div className="mt-3 flex items-center justify-between">
                                                <Likes
                                                like="10"
                                                classes="flex items-center space-x-1"
                                                />
                                                 <span className="text-accent text-lg font-display tracking-tight">
                                                    {[price]?.map((item) => {
                                                            const { _id, basic } = item;
                                                            return (
                                                                <>
                                                                <span className="text-xs text-jacarta-400  text-default">FROM  </span>${item[0].price} 
                                                                </>
                                                                );
                                                            })}
                                                </span>
                                            </div>
                                        </div>
                                        </div>
                                        </Link>
                                </article>
                                );
                            })}

                            </div>
            




                          

                                        
                            </div>
    </div>
  );
};

export default ServiceItem;
