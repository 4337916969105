import React, { useState } from "react";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper";
import Image from "next/image";
import {
    slider_1_data
} from "../../data/slider_1_data";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const Slider_1 = () => {
  const [activeThumb, setActiveThumb] = useState(null);

  return (
    <>
      {/* <!-- Hero Slider --> */}
      <section className="relative mt-20 ">
        <Swiper
          modules={[FreeMode, Navigation, Thumbs, Autoplay]}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{
            swiper: activeThumb && !activeThumb.destroyed ? activeThumb : null,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          className="full-slider"
        >
          {slider_1_data.map((item) => {
            const { image, id, title, name, titleLink, userLink } = item;
            return (
              <SwiperSlide key={id}>
                <div className="swiper-slide after:bg-jacarta-900/60 after:absolute ">
                  <div className="container relative z-10 w-1\/2 pt-20 pb-20 max-w-[91rem] px-4">
                    <h2 className="font-display text-4xl font-semibold dark:text-white mb-2">
                      <Link href={titleLink}>
                        <a>{title}</a>
                      </Link>
                    </h2>
                    <Link href={userLink} >
                      <a className="text-xl font-medium dark:text-white">{name}</a>
                    </Link>
                    <button className="px-6 py-2 mt-4 js-wallet border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex items-center justify-center rounded-full  bg-accent text-white transition-colors border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]">
                        Learn More
                    </button>

                  </div>
                  
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

       
      </section>
    </>
  );
};

export default Slider_1;
