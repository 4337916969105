import React, { useEffect, useState } from "react";
import { tranding_category_filter } from "../../data/categories_data";
import CategoryItem from "./categoryItem";
import { items_data } from "../../data/items_data";
import Tippy from "@tippyjs/react";
import Recently_added_dropdown from "../dropdown/recently_added_dropdown";
import { useSelector, useDispatch } from "react-redux";
import { updateTrendingCategoryItemData } from "../../redux/counterSlice";

const Trending_categories_items = ({category, services}) => {
  const [itemdata, setItemdata] = useState(items_data);
  const dispatch = useDispatch();
  const { trendingCategorySorText } = useSelector((state) => state.counter);
  const [filterVal, setFilterVal] = useState(0);

  const handleFilter = (category) => {
    if (category !== "all") {
      setItemdata(
        items_data.filter((item) => item.category === category)
      );
    } else {
      setItemdata(items_data);
    }
  };



  useEffect(() => {
    dispatch(updateTrendingCategoryItemData(itemdata.slice(0, 15)));
  }, [itemdata, dispatch]);

  return (
    <>
      {/* <!-- Filter --> */}
      <div className="mb-8 flex flex-wrap items-center justify-between">

        {/* dropdown */}
       
      </div>

      {/* <!-- Grid --> */}
      
    </>
  );
};

export default Trending_categories_items;
