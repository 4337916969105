import React, { useState} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CachedIcon from '@mui/icons-material/Cached';
import { useDispatch } from 'react-redux';
import Link from 'next/link';
import { cartModalShow } from '../../redux/counterSlice';
import Cart_modal from "../modal/cartModal";
const ItemPackages = ({order, price, service}) => {


    const dispatch = useDispatch();
    const [tabsActive, setTabsActive] = useState("0");

  return (
    <>
      <div className="scrollbar-custom my-8  overflow-x-auto rounded-lg">

       
        {/* <!-- Tabs Nav --> */}
        <Tabs className="min-w-fit tabs rounded-2.5xl  dark:bg-jacarta-700 bg-white">
          <TabList className="nav nav-tabs flex justify-between  ">
            {/* <!-- Offers --> */}

            {price &&
            Object.keys(price).map((i) => {
              return (
                <Tab className="nav-price bg-transparent w-1/3" key={i}>
                  <button
                    type="button"
                    className={
                        
                      tabsActive === i 
                        ? "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex justify-center whitespace-nowrap py-3 px-6 dark:hover:text-white active w-full"
                        : "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex justify-center whitespace-nowrap py-3 px-6 dark:hover:text-white w-full "

                    
                    }
                    onClick={() => setTabsActive(i)}
                  >
                    
                    <span className="font-display font-xl py-2 ">
                      {price[i].package}
                    </span>
                  </button>
                </Tab>
              );
            })}
          </TabList>




          {price &&
          Object.keys(price).map((i) => {
              return (
                    <TabPanel key={i}>
                        {/* <!-- Details --> */}
                        <div  className="tab-pane fade" id={price[i].package} role="tabpanel" >
                            <div className="dark:bg-jacarta-700 rounded-t-2lg rounded-b-2lg rounded-tl-none bg-white p-2 md:p-6">

                                <h1 className="font-display text-jacarta-700 mb-6 text-2xl font-bold dark:text-white">
                                    $ {price[i].price}
                                </h1>
                                <p>{price[i].description}</p>
                                <br/>

                                <div className="flex space-x-4 mt-2 mb-6">
                                    <div className="w-1/2 flex space-x-4"> 
                                    <AccessTimeIcon/>
                                    <div>
                                        <span className="mb-3 block font-display text-base font-semibold text-jacarta-700 dark:text-white">
                                            {price[i].duration} Days Delivery
                                        </span>
                                    </div>
                                    </div>
                                    <div className="w-1/2 flex space-x-4"> 
                                    <CachedIcon/>
                                    <div>
                                        <span className="mb-3 block font-display text-base font-semibold text-jacarta-700 dark:text-white">
                                            {price[i].revisions} &nbsp;Revisions
                                        </span>
                                    </div>
                                    </div>
                                </div>

                        
                                {price[i].features?.map((item) => {
                                    return (
                                            <>
                                            <div className="flex space-x-4">

                                            <CheckIcon className="text-accent"/>
                                            <div>
                                                <span className="mb-3 block text-base text-jacarta-400 dark:text-white">
                                                    {item}
                                                </span>
                                            </div>
                                            </div>
                                            </>
                                            );
                                    })}
                                        


                                
                                  {order&&
                                    <>
                                    <Link href="#" className="mt-4">
                                        <button
                                            className="bg-accent hover:bg-accent-dark inline-block w-full rounded-full mt-12 py-3 px-8 text-center text-white transition-all"
                                            onClick={() => dispatch(cartModalShow())}
                                        >
                                            Place Order
                                        </button>
                                    </Link>
                                    
                                    <Cart_modal i={tabsActive} price={price} service={service}/>
                                    </>
                                  }
                                
                            
                            </div>
                        </div>

                
                    </TabPanel>
                );
            })}

        </Tabs>
      </div>
    </>
  );
};

export default ItemPackages;
